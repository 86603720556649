import { useState } from "react";
import Textarea from "../Textarea/Textarea";
import Sucess from "./Sucess";


function Content() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleDenunciaSubmit = () => {
        // Lógica para enviar a denúncia aqui
        // Após o envio bem-sucedido, defina isSubmitted como true
        setIsSubmitted(true);
    };


    return (
        <>

            {!isSubmitted ? (
                <div>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-5 font-inter pt-16">
                        <h1 className="text-black font-extrabold text-4xl max-sm:text-2xl">Ouvidoria</h1>
                        <p className="text-base">Bem-vindo à nossa plataforma de ouvidoria anônima. Sua voz é importante para nós.</p>
                        <p className="text-base">Sua identidade é mantida em sigilo e não solicitamos  dados pessoais. <span style={{ backgroundColor: 'yellow' }}>Caso queira fazer alguma reclamação, o canal adequado é o WhatsApp: (19) 99720-9278, na opção 2 – Pós vendas.</span></p>
                        <p className="text-base">Apenas pessoal autorizado terá acesso às sugestões, que serão usadas exclusivamente para investigações internas.</p>
                    </div>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto pt-12">
                        <p>Colabore anonimamente: sugira aqui.</p>
                        <Textarea onSubmit={handleDenunciaSubmit} />
                    </div>
                </div>
            ) : (
                <Sucess />
            )}
        </>
    );
}

export default Content;